import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import InputText from "../InputText";
import colors from "../../scss/old-form.scss";
import { postalCodes } from "../../containers/Form/postalCodes";

const PostalCodeInput = ({ control, setValue, errors }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cityConfirmation, setCityConfirmation] = useState('');

  const handleSuggestionClick = (suggestion) => {
    setValue("postalCode", suggestion.value);
    const cityName = suggestion.label.split(" (")[0];
    setValue("city", cityName);
    setValue("address", `${cityName} (${suggestion.value})`);
    setCityConfirmation(`Vous êtes à ${cityName}`);
    setShowSuggestions(false);
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    
    // Only show suggestions when exactly 5 digits are entered
    if (value.length === 5 && /^\d{5}$/.test(value)) {
      const matches = postalCodes.filter(pc => 
        pc.value === value || 
        pc.label.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(matches);
      setShowSuggestions(matches.length > 0);

      // If there's an exact match
      const exactMatch = postalCodes.find(pc => pc.value === value);
      if (exactMatch) {
        const cityName = exactMatch.label.split(" (")[0];
        setCityConfirmation(`Vous êtes à ${cityName}`);
        setValue("city", cityName);
        setValue("address", `${cityName} (${value})`);
      } else {
        setCityConfirmation("Ce code postal n'existe pas dans notre base de données");
        setValue("city", "");
        setValue("address", "");
      }
    } else {
      // Clear suggestions and city confirmation if not 5 digits
      setSuggestions([]);
      setShowSuggestions(false);
      setCityConfirmation('');
      setValue("city", "");
      setValue("address", "");
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <InputText
        label="Quelle est votre code postal ?"
        placeholder="Code Postal"
        name="postalCode"
        control={control}
        error={errors.postalCode}
        onChange={handlePostalCodeChange}
      />
      {cityConfirmation && (
        <Typography 
          variant="h4" 
          className="semi-bold" 
          sx={{ 
            mt: 1,
            color: cityConfirmation.includes("n'existe pas") ? colors.red : colors.primary
          }}
        >
          {cityConfirmation}
        </Typography>
      )}
      {showSuggestions && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            zIndex: 10,
            backgroundColor: "white",
            border: `1px solid ${colors.secondary}`,
            borderRadius: "8px",
            mt: 1,
            maxHeight: "200px",
            overflowY: "auto"
          }}
        >
          {suggestions.map((suggestion, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: colors.background
                }
              }}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              <Typography variant="h4" className="semi-bold">
                {suggestion.label}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PostalCodeInput;
