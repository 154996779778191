// components/CustomLink.jsx
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function CustomLink({ href, children, ...props }) {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();

        if (href.includes('#')) {
            // Extract the base path and hash
            const [path, hash] = href.split('#');

            // If we're not on the home page, we need to navigate first
            if (location.pathname !== '/') {
                navigate('/', { state: { scrollTo: hash } });
            } else {
                // If we're already on home page, just scroll
                const element = document.getElementById(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        } else {
            // For non-hash links, just navigate normally
            navigate(href);
        }
    };

    // Handle scrolling when coming from another page
    useEffect(() => {
        if (location.state?.scrollTo) {
            setTimeout(() => {
                const element = document.getElementById(location.state.scrollTo);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); // Small delay to ensure page is loaded
        }
    }, [location]);

    return (
        <a href={href} onClick={handleClick} {...props}>
            {children}
        </a>
    );
}