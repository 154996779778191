// In FormPageNewDesign.jsx
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../oldForm/theme"; // copy your old theme.js into oldForm folder
import Form from "../../oldForm/containers/Form";
import DepartmentSectionStyle6 from "../Section/DepartmentSection/DepartmentSectionStyle6";
import Section from "../Section";
import TestimonialSectionStyle4 from "../Section/TestimonialSection/TestimonialSectionStyle4";

import FaqSection from "../Section/FaqSection";

const testimonialData = [
  {
    text: '"Très bon suivi de mon dossier avec mon conseiller Julien Muller.”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2.png",
    avatarName: "Aurélie Az",
    avatarDesignation: "01.2024",
  },
  {
    text: "\"Service Très réactif, Mme Lambert est toujours à l'écoute...”",
    ratingNumber: "4",
    avatarImgUrl: "/images/home_4/avatar_3.png",
    avatarName: "Stéphane",
    avatarDesignation: "02.2024",
  },
  {
    text: "“Des tarifs compétitifs j'ai réalisé 300 euros d'économies. Merci Diapzone.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4.png",
    avatarName: "Claudine",
    avatarDesignation: "02.2024",
  },
  {
    text: "\"La dame que j'ai eu par téléphone m'a bien renseigné, elle a pris le temps de m'expliquer et de m'accompagner dans toutes les démarches. Merci.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_2.png",
    avatarName: "Florence",
    avatarDesignation: "01.2024",
  },
  {
    text: '"Je suis très satisfaite, rapide, et simple. les prix sont corrects. Merci”',
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_3.png",
    avatarName: "Chantal",
    avatarDesignation: "03.2024",
  },
  {
    text: "“Je suis satisfait du service, les prix me convient, les conseillers sont toujours aimable et prennent en charge mes demandes.”",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_4/avatar_4.png",
    avatarName: "Hervé",
    avatarDesignation: "03.2024",
  },
];

const faqData = [
  {
    title: "Pourquoi la complémentaire santé est-elle importante ?",
    content:
      "Aujourd’hui, il devient indispensable de souscrire une mutuelle avec des bonnes garanties pour pallier ses frais de santé. La Sécurité sociale prend, certes, en charge une partie des dépenses mais ses remboursements sont bien souvent insuffisants. Par exemple, pour les soins optiques, les remboursements sont très dérisoires. Sans complémentaire santé, votre reste à charge sur vos dépenses médicales sera très élevé.",
  },
  {
    title:
      "Comment puis-je être sûr(e) de choisir la mutuelle qui me convient le mieux ?",
    content:
      "Diapazone propose un service unique avec des experts santé dédiés. Après avoir rempli votre profil, vous avez la possibilité de bénéficier d'un entretien personnalisé avec un expert santé qui vous guidera tout au long du processus de la comparaison à la souscription et répondra à toutes vos questions.",
  },
  {
    title: "Comment Résiler ma mutuelle ?",
    content:
      "Il est possible de résilier votre mutuelle à l’échéance annuelle de votre contrat en respectant un préavis de deux mois si votre adhésion date de moins d’un an. Ou, après une année de contrat, à tout moment, sans frais ni justificatif. Nos services s’occupent de toutes les démarches pour vous gratuitement.",
  },
  {
    title: "Quelle est la mutuelle la moins chère ?",
    content:
      "Le choix dépend entièrement de votre profil et de vos besoins en santé. Plutôt que de privilégier la recherche de la mutuelle la moins coûteuse, nous vous suggérons de mettre l'accent sur celle qui offre le meilleur équilibre entre garanties et prix.",
  },
];

const departmentData = [
  {
    title: "Service 100% Gratuit",
    iconUrl: "images/home_1/department_icon_1.svg",
    href: "/departments/department-details",
  },
  {
    title: "Jusqu'à 470 euros* d'économies",
    iconUrl: "images/home_1/department_icon_2.svg",
    href: "/departments/department-details",
  },
  {
    title: "Accompagnement 7/7",
    iconUrl: "images/home_1/department_icon_3.svg",
    href: "/departments/department-details",
  },
  {
    title:
      "La résiliation de votre ancien contrat gratuitement et Mise en place du nouveau contrat",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/departments/department-details",
  },
  {
    title: "Une Équipe de Professionnels Dévoués",
    iconUrl: "images/home_1/department_icon_5.svg",
    href: "/departments/department-details",
  },
  {
    title: "Des Valeurs Fondamentales Solides",
    iconUrl: "images/home_1/department_icon_6.svg",
    href: "/departments/department-details",
  },
];

const FormPageNewDesign = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="old-form-container">
        <Form />
      </div>
      <Section id="departments">
        <DepartmentSectionStyle6
          sectionTitle="On s'occupe de tout!"
          sectionTitleUp="Pourquoi nous choisir?"
          data={departmentData}
        />
      </Section>
      <Section
        topMd={140}
        topLg={100}
        topXl={175}
        bottomMd={100}
        bottomLg={50}
        bottomXl={10}
      >
        <TestimonialSectionStyle4
          sectionTitle="Plus de 5000 clients nous font confiance"
          sectionTitleUp="97% de clients satisfaits"
          avatarListTitle="150+ patients are reviews about ProHealth"
          avatarList={[
            { imgUrl: "/images/home_4/avatar_1.png" },
            { imgUrl: "/images/home_4/avatar_2.png" },
            { imgUrl: "/images/home_4/avatar_3.png" },
            { imgUrl: "/images/home_4/avatar_4.png" },
            { imgUrl: "/images/home_4/avatar_5.png" },
          ]}
          data={testimonialData}
        />
      </Section>
      <Section topMd={80} topLg={60} topXl={40} bottomMd={100}
        bottomLg={50}
        bottomXl={100}>
        <FaqSection
          data={faqData}
          sectionTitle="à vos questions!"
          sectionTitleUp="On répond"
        />
      </Section>
    </ThemeProvider>
  );
};

export default FormPageNewDesign;
