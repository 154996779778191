// components/HeaderLink.jsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function HeaderLink({ to, children, className }) {
    const location = useLocation();
    const navigate = useNavigate();

    const closeMobileMenu = () => {
        const navList = document.querySelector('.cs_nav_list');
        const menuToggle = document.querySelector('.cs_menu_toggle');

        if (navList?.classList.contains('cs_active')) {
            navList.classList.remove('cs_active');
            menuToggle?.classList.remove('cs_teggle_active');
        }
    };

    const handleClick = (e) => {
        e.preventDefault();

        // Close mobile menu first
        closeMobileMenu();

        if (to.includes('#')) {
            const [path, hash] = to.split('#');

            if (location.pathname !== '/') {
                navigate('/', { state: { scrollTo: hash } });
            } else {
                const element = document.getElementById(hash);
                if (element) {
                    // Add a small delay to ensure menu closing animation completes
                    setTimeout(() => {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }, 300);
                }
            }
        } else {
            navigate(to);
        }
    };

    useEffect(() => {
        if (location.state?.scrollTo) {
            closeMobileMenu(); // Ensure menu is closed when navigating from another page
            setTimeout(() => {
                const element = document.getElementById(location.state.scrollTo);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300); // Increased delay to ensure proper rendering
        }
    }, [location]);

    return (
        <a href={to} onClick={handleClick} className={className}>
            {children}
        </a>
    );
}