import React from 'react';
import { Link } from 'react-router-dom';
import CustomLink from '../CustomLink';
export default function MenuWidget({ data }) {
  return (
    <ul className="cs_menu_widget cs_mp0">
      {data?.map((item, index) => (
        <li key={index}>
          <CustomLink href={item.href}>{item.title}</CustomLink>
        </li>
      ))}
    </ul>
  );
}
