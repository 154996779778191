import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import colors from "../../scss/old-form.scss";

const LoadingSection = () => {
  return (
    <Box sx={{ 
      maxWidth: "800px", 
      margin: "120px auto",
      padding: "0 20px",
      textAlign: "center" 
    }}>
      <Typography 
        variant="h3" 
        className="semi-bold"
        sx={{ 
          color: colors.secondary,
          marginBottom: "40px",
          lineHeight: 1.5
        }}
      >
        Nous analysons actuellement pour vous les offres les mieux adaptées à votre profil senior.
      </Typography>

      <LinearProgress
        sx={{
          height: 8,
          backgroundColor: "#e0e0e0",
          borderRadius: 5,
          margin: "40px 0",
          "& .MuiLinearProgress-bar": {
            borderRadius: 5,
            backgroundColor: colors.secondary,
          },
        }}
      />

      <Typography 
        variant="h3" 
        className="semi-bold"
        sx={{ 
          color: colors.secondary,
          marginTop: "40px",
          lineHeight: 1.5
        }}
      >
        Chaque solution est minutieusement étudiée pour répondre à vos attentes en termes de budget et de couverture.
      </Typography>
    </Box>
  );
};

export default LoadingSection;