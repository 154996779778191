import React from 'react';
import { Box, Typography } from '@mui/material';
import avatar from "../../assets/images/avatar.png";
import colors from "../../scss/old-form.scss";

const AdvisorGreeting = ({ step, customMessage }) => {
  const messages = {
    0: {
      greeting: "Bonjour ! Je suis Eva, votre conseillère dédiée. C'est partit pour dénicher l'offre parfaite pour vous !",
      highlight: "Économisez jusqu'à 470€ en seulement 2 minutes !"
    },
    1: {
      conversation: "Pour commencer, discutons ensemble de vos besoins",
      highlight: "Un devis gratuit, sans engagement, et totalement personnalisé."
    },
    2: {
      conversation: "Maintenant, parlons un peu de vous !",
      highlight: "Un devis gratuit, sans engagement, et totalement personnalisé."
    }
  };

  const renderMessage = () => {
    if (customMessage) {
      return (
        <Typography
          sx={{
            color: colors.black,
            fontSize: { phone: '0.8rem', xxxs: '0.9rem' },
            lineHeight: 1.5
          }}
        >
          {customMessage}
        </Typography>
      );
    }

    return (
      <>
        <Typography
          sx={{
            color: colors.black,
            fontSize: { phone: '0.8rem', xxxs: '0.9rem' },
            lineHeight: 1.5,
            marginBottom: step === 0 ? '8px' : '4px' // Reduced spacing
          }}
        >
          {messages[step].conversation || messages[step].greeting}
        </Typography>
        <Box
          sx={{
            display: 'inline-block',
            backgroundColor: `${colors.primary}40`,
            padding: step === 0 ? '6px 12px' : '4px 10px',
            borderRadius: '4px',
            mt: 0.5 // Reduced top margin
          }}
        >
          <Typography
            sx={{
              color: colors.secondary, // Changed to secondary color (red)
              fontSize: { 
                phone: step === 0 ? '1rem' : '0.9rem', 
                xxxs: step === 0 ? '1.1rem' : '1rem' 
              },
              fontWeight: 700,
              lineHeight: 1.5,
              letterSpacing: step === 0 ? '0.02em' : 'normal'
            }}
          >
            {messages[step].highlight}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: '16px',
        marginBottom: '32px',
        marginTop: '40px'
      }}
    >
      <Box
        sx={{
          width: { phone: '60px', xxxs: '80px' },
          height: { phone: '60px', xxxs: '80px' },
          flexShrink: 0,
        }}
      >
        <img 
          src={avatar}
          alt="Eva votre conseillère"
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            objectFit: 'cover'
          }}
        />
      </Box>
      
      <Box sx={{ flex: 1 }}>
        <Typography 
          sx={{ 
            color: colors.secondary,
            fontSize: { phone: '0.9rem', xxxs: '1rem' },
            fontWeight: 600,
            marginBottom: '12px'
          }}
        >
          Eva
        </Typography>
        {renderMessage()}
      </Box>
    </Box>
  );
};

export default AdvisorGreeting;