import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import colors from "../../scss/old-form.scss";
import coverityLogo from "../../assets/images/Coverity.png";
import spvieLogo from "../../assets/images/spvie.png";
import { Check } from "lucide-react";
import AdvisorGreeting from "../AdvisorGreeting";
const StyledCard = styled(Card)({
  borderRadius: "25px",
  overflow: "hidden",
  transition: "all 0.3s ease-in-out",
  backgroundColor: colors.white,
  border: `2px solid ${colors.secondary}`,
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: `0 12px 20px ${colors.disabled}`,
  },
});

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Box)({
  backgroundColor: colors.white,
  borderRadius: "25px",
  padding: "2rem",
  maxWidth: "500px",
  width: "90%",
  textAlign: "center",
  position: "relative",
  outline: "none",
  "& .success-icon": {
    color: colors.primary,
    marginBottom: "1.5rem",
  },
});

const ReturnButton = styled(Button)({
  backgroundColor: colors.secondary,
  color: colors.white,
  "&:hover": {
    backgroundColor: colors.primary,
  },
  marginTop: "2rem",
  padding: "8px 24px",
  borderRadius: "25px",
  "& .MuiTypography-root": {
    fontSize: "14px !important",
  },
});

const PlanTitle = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: 550,
  color: colors.primary,
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${colors.secondary} !important`,
  color: colors.white,
  "&:hover": {
    backgroundColor: `${colors.primary} !important`,
  },
  padding: "8px 16px",
  minWidth: "140px",
  height: "52px",
  marginLeft: "0.5rem",
  marginTop: "1rem",
  textTransform: "none",
  borderRadius: "25px",
  // // Target the internal span element that MUI uses for button text
  // "& .MuiButton-root": {
  //   fontSize: "12px !important",
  // },
  // "& .MuiButton-startIcon": {
  //   margin: 0,
  // },
  // // Target the actual text content
  // "& .MuiTypography-root": {
  //   fontSize: "8px !important",
  // },
  // // Target the button label
  // "& .MuiButton-label": {
  //   fontSize: "2px !important",
  // },
  // // Target any text directly inside the button
  // "& > span": {
  //   fontSize: "2px !important",
  //   textTransform: "none",
  // },
  "@media (max-width: 600px)": {
    height: "32px",
    "& .MuiTypography-root": {
      fontSize: "8px !important",
    },
  }
}));
// const productKey = `${results.assureur}-${results.planName}`;
const PlanSubtitle = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 550,
  color: colors.secondary,
});

const Price = styled(Typography)({
  fontSize: "1.8rem",
  fontWeight: 550,
  color: colors.primary,
});

const CoverageItem = styled(Typography)({
  fontSize: "0.8rem",
  color: colors.black,
  marginBottom: "2px",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "8px", // Space between buttons
  marginTop: "1rem",
  flexWrap: "wrap", // Allows wrapping on smaller screens
  "@media (max-width: 600px)": {
    justifyContent: "center",
    gap: "8px",
  }
});

const coverageDatabase = {
  "SANTE NOVA": {
    "Niveau A": {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "-",
    },
    "Niveau B": {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "125%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "30€ /an",
    },
    "Niveau C": {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "165%",
      "Montures + 2 verres complexes (classes B)": "150€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "30€ /an",
    },
    "Niveau D": {
      "Honoraires chirurgie (DPTAM)": "175%",
      "Médecins (DPTAM)": "175%",
      "Prothèses dentaires remboursées": "190%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "40€ /an",
    },
    "Niveau E": {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "215%",
      "Montures + 2 verres complexes (classes B)": "275€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "50€ /an",
    },
  },
  "SMART SANTE V3": {
    AE: {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "-",
    },
    BE: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "125%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + 25€",
      "Médecine naturelle": "-",
    },
    CE: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "150%",
      "Montures + 2 verres complexes (classes B)": "250€",
      "Audio-prothèses (Classe II)": "100% + 50€",
      "Médecine naturelle": "-",
    },
    DE: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "175%",
      "Montures + 2 verres complexes (classes B)": "300€",
      "Audio-prothèses (Classe II)": "100% + 150€",
      "Médecine naturelle": "-",
    },
    AC: {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "-",
    },
    BC: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "125%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + 50€",
      "Médecine naturelle": "-",
    },
    CC: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "150%",
      "Montures + 2 verres complexes (classes B)": "250€",
      "Audio-prothèses (Classe II)": "100% + 100€",
      "Médecine naturelle": "60€ /an",
    },
    DC: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "175%",
      "Montures + 2 verres complexes (classes B)": "300€",
      "Audio-prothèses (Classe II)": "100% + 200€",
      "Médecine naturelle": "90€ /an",
    },
    EC: {
      "Honoraires chirurgie (DPTAM)": "300%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "225%",
      "Montures + 2 verres complexes (classes B)": "350€",
      "Audio-prothèses (Classe II)": "100% + 250€",
      "Médecine naturelle": "120€ /an",
    },
    FC: {
      "Honoraires chirurgie (DPTAM)": "350%",
      "Médecins (DPTAM)": "300%",
      "Prothèses dentaires remboursées": "300%",
      "Montures + 2 verres complexes (classes B)": "400€",
      "Audio-prothèses (Classe II)": "100% + 300€",
      "Médecine naturelle": "150€ /an",
    },
    CF: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "150%",
      "Montures + 2 verres complexes (classes B)": "250€",
      "Audio-prothèses (Classe II)": "100% + 100€",
      "Médecine naturelle": "-",
    },
    DF: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "175%",
      "Montures + 2 verres complexes (classes B)": "300€",
      "Audio-prothèses (Classe II)": "100% + 200€",
      "Médecine naturelle": "-",
    },
    EF: {
      "Honoraires chirurgie (DPTAM)": "300%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "225%",
      "Montures + 2 verres complexes (classes B)": "350€",
      "Audio-prothèses (Classe II)": "100% + 250€",
      "Médecine naturelle": "-",
    },
    FF: {
      "Honoraires chirurgie (DPTAM)": "350%",
      "Médecins (DPTAM)": "300%",
      "Prothèses dentaires remboursées": "300%",
      "Montures + 2 verres complexes (classes B)": "400€",
      "Audio-prothèses (Classe II)": "100% + 300€",
      "Médecine naturelle": "-",
    },
  },
  "SANTE SENIOR": {
    "Niveau A": {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "175€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "-",
    },
    "Niveau B": {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "125%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "125%",
      "Médecine naturelle": "90€ /an",
    },
    "Niveau C": {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "150%",
      "Montures + 2 verres complexes (classes B)": "225€",
      "Audio-prothèses (Classe II)": "150%",
      "Médecine naturelle": "150€ /an",
    },
    "Niveau D": {
      "Honoraires chirurgie (DPTAM)": "175%",
      "Médecins (DPTAM)": "175%",
      "Prothèses dentaires remboursées": "165%",
      "Montures + 2 verres complexes (classes B)": "250€",
      "Audio-prothèses (Classe II)": "160%",
      "Médecine naturelle": "225€ /an",
    },
    "Niveau E": {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "180%",
      "Montures + 2 verres complexes (classes B)": "275€",
      "Audio-prothèses (Classe II)": "170%",
      "Médecine naturelle": "300€ /an",
    },
  },
  "SANTE MALIN": {
    "Formule 1": {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "-",
    },
    "Formule 2": {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "125%",
      "Montures + 2 verres complexes (classes B)": "225€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "80€ /an",
    },
    "Formule 3": {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "150%",
      "Montures + 2 verres complexes (classes B)": "225€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "110€ /an",
    },
  },
};

const coverityMapping = {
  "SWISS LIFE": {
    formules: {
      FH1: { name: "SANTE SENIOR HOSPI", formule: "FH1" },
      FH2: { name: "SANTE SENIOR HOSPI", formule: "FH2" },
      FH3: { name: "SANTE SENIOR HOSPI", formule: "FH3" },
      "FH3 + S": { name: "SANTE SENIOR HOSPI", formule: "FH3 + S" },
      FH4: { name: "SANTE SENIOR HOSPI", formule: "FH4" },
      "FH4 + S": { name: "SANTE SENIOR HOSPI", formule: "FH4 + S" },
      FH5: { name: "SANTE SENIOR HOSPI", formule: "FH5" },
      "FH5 + S": { name: "SANTE SENIOR HOSPI", formule: "FH5 + S" },
      F1: { name: "SANTE SENIOR", formule: "F1" },
      F2: { name: "SANTE SENIOR", formule: "F2" },
      F3: { name: "SANTE SENIOR", formule: "F3" },
      "F3 + S1": { name: "SANTE SENIOR", formule: "F3 + S1" },
      "F3 + S2": { name: "SANTE SENIOR", formule: "F3 + S2" },
      "F3 + S1 + S2": { name: "SANTE SENIOR", formule: "F3 + S1 + S2" },
      F4: { name: "SANTE SENIOR", formule: "F4" },
      "F4 + S1": { name: "SANTE SENIOR", formule: "F4 + S1" },
      "F4 + S2": { name: "SANTE SENIOR", formule: "F4 + S2" },
      "F4 + S1 + S2": { name: "SANTE SENIOR", formule: "F4 + S1 + S2" },
      F5: { name: "SANTE SENIOR", formule: "F5" },
      "F5 + S1": { name: "SANTE SENIOR", formule: "F5 + S1" },
      "F5 + S2": { name: "SANTE SENIOR", formule: "F5 + S2" },
      "F5 + S1 + S2": { name: "SANTE SENIOR", formule: "F5 + S1 + S2" },
    },
  },
  "NEW COVER SANTE HOSPI & SOINS COURANTS": {
    formules: {
      TCH1: {
        name: "NEW COVER HOSPITALISATION ET SOINS COURANTS",
        formule: "TCH1",
      },
      TCH2: {
        name: "NEW COVER HOSPITALISATION ET SOINS COURANTS",
        formule: "TCH2",
      },
      TCH3: {
        name: "NEW COVER HOSPITALISATION ET SOINS COURANTS",
        formule: "TCH3",
      },
      TCH4: {
        name: "NEW COVER HOSPITALISATION ET SOINS COURANTS",
        formule: "TCH4",
      },
      TCH5: {
        name: "NEW COVER HOSPITALISATION ET SOINS COURANTS",
        formule: "TCH5",
      },
      TC2: { name: "NEW COVER 100% SANTE", formule: "TC2" },
      TC3: { name: "NEW COVER 100% SANTE", formule: "TC3" },
      TC4: { name: "NEW COVER 100% SANTE", formule: "TC4" },
      TC5: { name: "NEW COVER 100% SANTE", formule: "TC5" },
    },
  },
  SMATIS: {
    formules: {
      CH2: {
        name: "COVER SANTE 50 PLUS HOSPITALISATION ET SOINS COURANTS",
        formule: "CH2",
      },
      CH3: {
        name: "COVER SANTE 50 PLUS HOSPITALISATION ET SOINS COURANTS",
        formule: "CH3",
      },
      CH4: {
        name: "COVER SANTE 50 PLUS HOSPITALISATION ET SOINS COURANTS",
        formule: "CH4",
      },
      CH5: {
        name: "COVER SANTE 50 PLUS HOSPITALISATION ET SOINS COURANTS",
        formule: "CH5",
      },
      C1: { name: "COVER SANTE 50 PLUS", formule: "C1" },
      C2: { name: "COVER SANTE 50 PLUS", formule: "C2" },
      C3: { name: "COVER SANTE 50 PLUS", formule: "C3" },
      C4: { name: "COVER SANTE 50 PLUS", formule: "C4" },
      C5: { name: "COVER SANTE 50 PLUS", formule: "C5" },
    },
  },
  INSUREM: {
    formules: {
      TH1: { name: "COVER SANTE TNS PLUS HOSPI", formule: "TH1" },
      TH2: { name: "COVER SANTE TNS PLUS HOSPI", formule: "TH2" },
      "TH2 + SURCO": {
        name: "COVER SANTE TNS PLUS HOSPI",
        formule: "TH2 + SURCO",
      },
      TH3: { name: "COVER SANTE TNS PLUS HOSPI", formule: "TH3" },
      "TH3 + SURCO": {
        name: "COVER SANTE TNS PLUS HOSPI",
        formule: "TH3 + SURCO",
      },
      TH4: { name: "COVER SANTE TNS PLUS HOSPI", formule: "TH4" },
      "TH4 + SURCO": {
        name: "COVER SANTE TNS PLUS HOSPI",
        formule: "TH4 + SURCO",
      },
      TH5: { name: "COVER SANTE TNS PLUS HOSPI", formule: "TH5" },
      "TH5 + SURCO": {
        name: "COVER SANTE TNS PLUS HOSPI",
        formule: "TH5 + SURCO",
      },
      TH6: { name: "COVER SANTE TNS PLUS HOSPI", formule: "TH6" },
      "TH6 + SURCO": {
        name: "COVER SANTE TNS PLUS HOSPI",
        formule: "TH6 + SURCO",
      },
      T1: { name: "COVER SANTE TNS PLUS", formule: "T1" },
      T2: { name: "COVER SANTE TNS PLUS", formule: "T2" },
      "T2 + SURCO": { name: "COVER SANTE TNS PLUS", formule: "T2 + SURCO" },
      T3: { name: "COVER SANTE TNS PLUS", formule: "T3" },
      "T3 + SURCO": { name: "COVER SANTE TNS PLUS", formule: "T3 + SURCO" },
      T4: { name: "COVER SANTE TNS PLUS", formule: "T4" },
      "T4 + SURCO": { name: "COVER SANTE TNS PLUS", formule: "T4 + SURCO" },
      T5: { name: "COVER SANTE TNS PLUS", formule: "T5" },
      "T5 + SURCO": { name: "COVER SANTE TNS PLUS", formule: "T5 + SURCO" },
      T6: { name: "COVER SANTE TNS PLUS", formule: "T6" },
      "T6 + SURCO": { name: "COVER SANTE TNS PLUS", formule: "T6 + SURCO" },
    },
  },
  ACHEEL: {
    formules: {
      ECO: { name: "COVER SANTE TONUS", formule: "ECO" },
      SC1: { name: "COVER SANTE TONUS", formule: "SC1" },
      SC2: { name: "COVER SANTE TONUS", formule: "SC2" },
      "SC2 + SURCO": { name: "COVER SANTE TONUS", formule: "SC2 + SURCO" },
      SC3: { name: "COVER SANTE TONUS", formule: "SC3" },
      "SC3 + SURCO": { name: "COVER SANTE TONUS", formule: "SC3 + SURCO" },
      SC4: { name: "COVER SANTE TONUS", formule: "SC4" },
      "SC4 + SURCO": { name: "COVER SANTE TONUS", formule: "SC4 + SURCO" },
      SC5: { name: "COVER SANTE TONUS", formule: "SC5" },
      "SC5 + SURCO": { name: "COVER SANTE TONUS", formule: "SC5 + SURCO" },
    },
  },
};

const coverityDatabase = {
  "SANTE SENIOR": {
    F1: {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "-",
    },
    F2: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "30€ /an",
    },
    F3: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "125%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100%+ 50€",
      "Médecine naturelle": "60€ /an",
    },
    F4: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "150%",
      "Montures + 2 verres complexes (classes B)": "225€",
      "Audio-prothèses (Classe II)": "100%+ 100€",
      "Médecine naturelle": "90€ /an",
    },
    F5: {
      "Honoraires chirurgie (DPTAM)": "250%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "200%",
      "Montures + 2 verres complexes (classes B)": "250€",
      "Audio-prothèses (Classe II)": "100%+ 150€",
      "Médecine naturelle": "120€ /an",
    },
  },
  "NEW COVER 100% SANTE": {
    TC2: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "200%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
    TC3: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "200%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
    TC4: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "200%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
    TC5: {
      "Honoraires chirurgie (DPTAM)": "250%",
      "Médecins (DPTAM)": "250%",
      "Prothèses dentaires remboursées": "200%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
  },
  "SANTE SENIOR HOSPI": {
    FH1: { "Honoraires chirurgie (DPTAM)": "100%" },
    FH2: { "Honoraires chirurgie (DPTAM)": "125%" },
    FH3: { "Honoraires chirurgie (DPTAM)": "150%" },
    FH4: { "Honoraires chirurgie (DPTAM)": "200%" },
    FH5: { "Honoraires chirurgie (DPTAM)": "250%" },
  },
  "NEW COVER HOSPITALISATION ET SOINS COURANTS": {
    TCH1: {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Médecine naturelle": "60€ /an",
    },
    TCH2: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "125%",
      "Médecine naturelle": "60€ /an",
    },
    TCH3: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "150%",
      "Médecine naturelle": "60€ /an",
    },
    TCH4: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "200%",
      "Médecine naturelle": "60€ /an",
    },
    TCH5: {
      "Honoraires chirurgie (DPTAM)": "250%",
      "Médecins (DPTAM)": "250%",
      "Médecine naturelle": "60€ /an",
    },
  },
  "COVER SANTE 50 PLUS": {
    C1: {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
    },
    C2: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "200% + *150€",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
    C3: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "200% + *150€",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
    C4: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "200% + *150€",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
    C5: {
      "Honoraires chirurgie (DPTAM)": "250%",
      "Médecins (DPTAM)": "250%",
      "Prothèses dentaires remboursées": "200% + *150€",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100% + *100€",
      "Médecine naturelle": "60€ /an",
    },
  },
  "COVER SANTE 50 PLUS HOSPITALISATION ET SOINS COURANTS": {
    CH2: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "125%",
      "Médecine naturelle": "60€ /an",
    },
    CH3: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "150%",
      "Médecine naturelle": "60€ /an",
    },
    CH4: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "200%",
      "Médecine naturelle": "60€ /an",
    },
    CH5: {
      "Honoraires chirurgie (DPTAM)": "250%",
      "Médecins (DPTAM)": "250%",
      "Médecine naturelle": "60€ /an",
    },
  },
  "COVER SANTE TONUS": {
    ECO: {
      "Honoraires chirurgie (DPTAM)": "100%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "100%",
      "Audio-prothèses (Classe II)": "100%",
    },
    SC1: {
      "Honoraires chirurgie (DPTAM)": "125%",
      "Médecins (DPTAM)": "100%",
      "Prothèses dentaires remboursées": "100%",
      "Montures + 2 verres complexes (classes B)": "200€",
      "Audio-prothèses (Classe II)": "100%",
      "Médecine naturelle": "30€ /an",
    },
    SC2: {
      "Honoraires chirurgie (DPTAM)": "150%",
      "Médecins (DPTAM)": "125%",
      "Prothèses dentaires remboursées": "125%",
      "Montures + 2 verres complexes (classes B)": "225€",
      "Audio-prothèses (Classe II)": "125%",
      "Médecine naturelle": "60€ /an",
    },
    SC3: {
      "Honoraires chirurgie (DPTAM)": "200%",
      "Médecins (DPTAM)": "150%",
      "Prothèses dentaires remboursées": "150%",
      "Montures + 2 verres complexes (classes B)": "250€",
      "Audio-prothèses (Classe II)": "150%",
      "Médecine naturelle": "90€ /an",
    },
    SC4: {
      "Honoraires chirurgie (DPTAM)": "250%",
      "Médecins (DPTAM)": "200%",
      "Prothèses dentaires remboursées": "200%",
      "Montures + 2 verres complexes (classes B)": "300€",
      "Audio-prothèses (Classe II)": "200%",
      "Médecine naturelle": "120€ /an",
    },
    SC5: {
      "Honoraires chirurgie (DPTAM)": "300%",
      "Médecins (DPTAM)": "250%",
      "Prothèses dentaires remboursées": "250%",
      "Montures + 2 verres complexes (classes B)": "350€",
      "Audio-prothèses (Classe II)": "250%",
      "Médecine naturelle": "150€ /an",
    },
  },
};

const combinedCoverageDatabase = {
  ...coverageDatabase,
  ...coverityDatabase,
};

const mapCoverityNames = (technicalName, technicalFormule) => {
  const product = coverityMapping[technicalName];
  if (product && product.formules[technicalFormule]) {
    const { name: commercialName, formule: commercialFormule } =
      product.formules[technicalFormule];
    return { commercialName, commercialFormule };
  }
  return { commercialName: technicalName, commercialFormule: technicalFormule };
};

const getProductDetails = (assureur, level) => {
  console.log("Searching for:", assureur, level);

  // First, try to find an exact match
  if (
    combinedCoverageDatabase[assureur] &&
    combinedCoverageDatabase[assureur][level]
  ) {
    console.log(
      "Found exact match:",
      combinedCoverageDatabase[assureur][level]
    );
    return combinedCoverageDatabase[assureur][level];
  }

  // If no exact match, search for partial matches
  const product = Object.keys(combinedCoverageDatabase).find((key) =>
    assureur.toLowerCase().includes(key.toLowerCase())
  );

  if (product && combinedCoverageDatabase[product][level]) {
    console.log(
      "Found coverage details:",
      combinedCoverageDatabase[product][level]
    );
    return combinedCoverageDatabase[product][level];
  }

  console.log("No coverage details found");
  return null;
};

const ApiResultsComponent = ({ results, userData }) => {
  console.log("ApiResultsComponent rendered with userData:", userData);
  const [quoteRequested, setQuoteRequested] = useState({});
  const [callRequested, setCallRequested] = useState({});
  const [quoteRequestStatus, setQuoteRequestStatus] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    console.log("userData updated:", userData);
  }, [userData]);

  if (!results) {
    return <Typography>Loading results...</Typography>;
  }

  console.log("Full results:", results);

  const getProductName = (category) => {
    switch (parseInt(category)) {
      case 8:
        return "SANTE SENIOR";
      case 25:
        return "SANTE MALIN";
      case 33:
      case 35:
        return "SMART SANTE V3";
      case 42:
        return "SANTE NOVA";
      default:
        return "Unknown";
    }
  };

  // Combine and format Coverity results
  // Combine and format Coverity results
  const coverityResults =
    results.coverity?.getTarifSanteResult?.formules
      ?.map((formule) => {
        const { commercialName, commercialFormule } = mapCoverityNames(
          formule.assureur?.nom,
          formule.libelle
        );
        return {
          provider: "Coverity",
          logo: coverityLogo,
          assureur: commercialName || "Unknown",
          planName: commercialFormule || "Unknown",
          monthlyPrice: formule.prime_mensuelle_ttc || 0,
          annualPrice: formule.prime_annuelle_ttc || 0,
        };
      })
      .filter(
        (result) =>
          result.monthlyPrice > 0 &&
          result.assureur !== "Unknown" &&
          result.planName !== "Unknown"
      ) || [];

  // Combine and format SPVIE results
  const spvieResults = Object.entries(results.spvie || {}).flatMap(
    ([category, categoryResults]) =>
      (categoryResults?.Prices || [])
        .map((price) => ({
          provider: "SPVIE",
          logo: spvieLogo,
          assureur: getProductName(parseInt(category)),
          planName: price.Level || "Unknown",
          monthlyPrice: price.Price || 0,
          annualPrice: (price.Price || 0) * 12,
        }))
        .filter(
          (result) => result.monthlyPrice > 0 && result.planName !== "Unknown"
        )
  );

  console.log("SPVIE Results:", spvieResults);
  console.log("Coverity Results:", coverityResults);

  // Combine all results and sort by monthly price
  const allResults = [...coverityResults, ...spvieResults].sort(
    (a, b) => a.monthlyPrice - b.monthlyPrice
  );

  if (allResults.length === 0) {
    return <Typography>No results available.</Typography>;
  }

  const handleQuoteRequest = async (product, productKey) => {
    console.log("handleQuoteRequest called with product:", product);
    console.log("Current userData:", userData);

    if (!userData) {
      console.error("User data is not available");
      setQuoteRequestStatus((prevState) => ({
        ...prevState,
        [productKey]: "Une erreur s'est produite. Veuillez réessayer.",
      }));
      return;
    }

    const quoteData = {
      userData: {
        lastName: userData.lastName || "Non renseigné",
        firstName: userData.firstName || "Non renseigné",
        email: userData.email || "Non renseigné",
        phoneNumber: userData.phoneNumber || "Non renseigné",
      },
      product: {
        assureur: product.assureur,
        planName: product.planName,
        monthlyPrice: product.monthlyPrice.toFixed(2),
        annualPrice: product.annualPrice.toFixed(2),
      },
      requestType: 'quote'
    };

    console.log(
      "Sending quote request with data:",
      JSON.stringify(quoteData, null, 2)
    );

    try {
      const response = await fetch(
        "https://diapazone.fr/api/sendQuoteRequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(quoteData),
        }
      );

      console.log("Response status:", response.status);
      const data = await response.json();
      console.log("Response data:", data);

      if (response.ok) {
        setQuoteRequested((prevState) => ({
          ...prevState,
          [productKey]: true,
        }));
      setModalType('quote');
      setIsModalOpen(true);
      } else {
        throw new Error(data.message || "Failed to send quote request");
      }
    } catch (error) {
      console.error("Error sending quote request:", error);
      setQuoteRequestStatus((prevState) => ({
        ...prevState,
        [productKey]:
          "Une erreur s'est produite lors de l'envoi de votre demande. Veuillez réessayer.",
      }));
    }
  };
  const handleCallRequest = async (product, productKey) => {
  console.log("handleCallRequest called with product:", product);
  console.log("Current userData:", userData);

  if (!userData) {
    console.error("User data is not available");
    setQuoteRequestStatus((prevState) => ({
      ...prevState,
      [productKey]: "Une erreur s'est produite. Veuillez réessayer.",
    }));
    return;
  }

  const callData = {
    userData: {
      lastName: userData.lastName || "Non renseigné",
      firstName: userData.firstName || "Non renseigné",
      email: userData.email || "Non renseigné",
      phoneNumber: userData.phoneNumber || "Non renseigné",
    },
    product: {
      assureur: product.assureur,
      planName: product.planName,
      monthlyPrice: product.monthlyPrice.toFixed(2),
      annualPrice: product.annualPrice.toFixed(2),
    },
    requestType: 'call'
  };

  try {
    const response = await fetch(
      "https://diapazone.fr/api/sendQuoteRequest",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(callData),
      }
    );

    if (response.ok) {
      setCallRequested((prevState) => ({
        ...prevState,
        [productKey]: true,
      }));
      setModalType('call');
      setIsModalOpen(true);
    } else {
      throw new Error("Failed to send call request");
    }
  } catch (error) {
    console.error("Error sending call request:", error);
    setQuoteRequestStatus((prevState) => ({
      ...prevState,
      [productKey]:
        "Une erreur s'est produite lors de l'envoi de votre demande. Veuillez réessayer.",
    }));
  }
};

  if (!results) {
    return <Typography>Loading results...</Typography>;
  }


  const resultsCount = allResults.length;
  return (
    <Box sx={{ maxWidth: "1200px", margin: "auto", padding: "2rem" }}>
      <Box sx={{ marginBottom: "40px" }}>
        <AdvisorGreeting 
          step={3} 
          customMessage={`Nous avons trouvé ${resultsCount} offres en adéquation avec vos besoins. Vous êtes à un clic d'accéder à LA meilleure offre de couverture santé !`}
        />
      </Box>
      <Grid container spacing={3}>
        {allResults.map((result, index) => {
          const coverageDetails = getProductDetails(
            result.assureur,
            result.planName
          );

          // Define a unique key for each product
          const productKey = `${result.assureur}-${result.planName}`;

          return (
            <Grid item xs={12} key={`result-${index}`}>
              <StyledCard>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1.5rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Box>
                      <img
                        src={result.logo}
                        alt={result.provider}
                        style={{ height: "75px", marginBottom: "0.5rem" }}
                      />
                      <PlanTitle>{result.assureur}</PlanTitle>
                      <PlanSubtitle>{result.planName}</PlanSubtitle>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Price>
                        {result.monthlyPrice.toFixed(2)} €{" "}
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: colors.black }}
                        >
                          /mois
                        </Typography>
                      </Price>
                      <Typography
                        variant="body2"
                        sx={{ color: colors.secondary, fontWeight: 550 }}
                      >
                        {result.annualPrice.toFixed(2)} € /an
                      </Typography>
                    </Box>
                  </Box>
                  {coverageDetails && (
                    <Box sx={{ marginTop: "1rem" }}>
                      <Typography
                        variant="h6"
                        sx={{ color: colors.primary, marginBottom: "0.5rem" }}
                      >
                        Détails de la couverture:
                      </Typography>
                      <Grid container spacing={1}>
                        {Object.entries(coverageDetails).map(([key, value]) => (
                          <Grid item xs={6} key={key}>
                            <CoverageItem>
                              {key}: <strong>{value}</strong>
                            </CoverageItem>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    )}
                  <ButtonContainer>
                    <StyledButton
                      onClick={() => handleQuoteRequest(result, productKey)}
                      disabled={quoteRequested[productKey]}
                    >
                      <Typography variant="body2">
                        Demandez votre devis
                      </Typography>
                    </StyledButton>
                    <StyledButton
                      onClick={() => handleCallRequest(result, productKey)}
                      disabled={callRequested[productKey]}
                    >
                      <Typography variant="body2">
                        Être appelé par un expert
                      </Typography>
                    </StyledButton>
                  </ButtonContainer>
                  {quoteRequestStatus[productKey] && (
                    <Typography
                      variant="body2"
                            sx={{ 
                                color: colors.secondary, 
                                marginTop: "0.5rem",
                                fontSize: "0.8rem",
                                textAlign: "right"
                              }}
                    >
                      {quoteRequestStatus[productKey]}
                    </Typography>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
          );

        })}
      </Grid>
      <StyledModal 
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
      >
        <ModalContent>
          <Check size={60} className="success-icon" />
          <Typography 
            variant="body1" 
            sx={{ 
              color: colors.primary,
              fontWeight: "600",
              marginBottom: "1rem"
            }}
          >
            Excellent choix ! Votre demande de {modalType === 'quote' ? 'devis' : 'mise en relation'} a bien été enregistrée
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: colors.black,
              marginBottom: "1rem"
            }}
          >
            Nous vous contacterons dans les plus brefs délais.
          </Typography>
          <ReturnButton onClick={() => setIsModalOpen(false)}>
            <Typography>
              Retour aux devis
            </Typography>
          </ReturnButton>
        </ModalContent>
      </StyledModal>
    </Box>

  );
};

export default ApiResultsComponent;
