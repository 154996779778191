import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import { useEffect } from "react";
import ErrorPage from "./components/Pages/ErrorPage";
import FormLayout from "./components/Layout/FormLayout";
import FormPageNewDesign from "./components/Pages/FormPageNewDesign";
import CGV from "./components/Pages/cgv";
import ML from "./components/Pages/Mentionslegales";
import TermOfUse from "./components/Pages/TermsOfUse";

function App() {
  const location = useLocation();
  const { pathname } = useLocation();
  useEffect(() => {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVH();
    window.addEventListener("resize", setVH);
    return () => window.removeEventListener("resize", setVH);
  }, []);

  useEffect(() => {
    // Only scroll to top if there's no hash navigation state
    if (!location.state?.scrollTo) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
      </Route>
      <Route path="/formulaire" element={<FormLayout />}>
        <Route path="step1" element={<FormPageNewDesign />} />
        <Route path="step2" element={<FormPageNewDesign />} />
        <Route path="step3" element={<FormPageNewDesign />} />
        <Route path="results/:id" element={<FormPageNewDesign />} />
        <Route index element={<FormPageNewDesign />} />
      </Route>
      <Route path="/cgv" element={<FormLayout />}>
          <Route index element={<CGV />} />
      </Route>
      <Route path="/Mentionslegales" element={<FormLayout />}>
          <Route index element={<ML/>} />
      </Route>
      <Route path="/TermOfUse" element={<FormLayout />}>
          <Route index element={<TermOfUse />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
