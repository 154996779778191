import React from 'react';
import WorkingProcess from '../Section/WorkingProcess';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';

const workingProcessData = [
    {
        title: "Propriétaire",
        subTitle:
            "OLM assurances – Courtier distributeur – S.A.R.L au capital social de 1000 euros. Siège social : 16 RUE CUVIER 69006 LYON. RCS Lyon B 922 287 735 –courtier d’assurances ORIAS N° 23000591 www.orias.fr Entreprise régie par le Code des assurances et soumise au contrôle de l’ACPR 4 Place de Budapest – CS 92459 – 75436 paris Cedex 09. RC Professionnelle et garantie financière conformes aux articles L. 512-6 et L. 512-7 du Code des assurances",
        iconUrl: "/images/home_2/wording_process_icon_1.svg",
        number: "01",
    },
    {
        title: "Éditeur du site",
        subTitle:
            "Site Service. Directeur de la publication : Monsieur GRAS OLIVIER Gérant de la société. Hébergeur : IONOS. Crédits photographiques : Toutes les photos sont libres de droits. Liens hypertextes : Le site Internet Assurecobudget.com peut offrir des liens vers d’autres sites à titre de commodité. Assurecobudget.com ne peut en aucun cas être tenue responsable du contenu de ces sites. La mise en place d’un lien hypertexte depuis un autre site Internet vers le site Assurecobudget.com doit faire l’objet d’une autorisation préalable.",
        iconUrl: "/images/home_2/wording_process_icon_3.svg",
        number: "02",
    },
];

export default function Mentionslegales() {
    pageTitle("Mentions légales");
    return (
        <>
            <div className="cs_section_spacing"></div>
            <div className="cs_section_spacing"></div>
            <Section
                topMd={185}
                topLg={140}
                topXl={100}
                bottomMd={200}
                bottomLg={150}
                bottomXl={110}
            >
                <WorkingProcess
                    sectionTitle="Mentions obligatoires et informations clés"
                    sectionTitleUp="Mentions légales"
                    sectionTitleDown=""
                    sectionSubTitle="Conformément aux dispositions des articles 6-III et 19 de la loi pour la
        confiance dans l’économie numérique, nous vous informons que :"
                    data={workingProcessData}
                />
            </Section>
            <div className="cs_section_spacing"></div>
            <div className="cs_section_spacing"></div>
        </>
    );
}